//** Theme Styles for Home, Static, and Process Pages
/// This style sheet is strictly for component/text colors. Component
/// formatting should be handled elsewhere (components.scss or styles.scss)
/// unless it is specific to the page being displayed on.

//** Theme Style for all Process Pages
//## Header Content Style
//## Breadcrumbs
//## Process Page Nav (Title w/ Navigation Buttons)
//## Default Button Style

/// A mixin used to create a new colored theme for
/// a specific page kind.
/// @param $name the name of the theme
/// @param $color the primary theme color
@mixin process-page-theme($name, $color) {
  &.pp-page-#{$name}, sws-error {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: white;


    //## Header Content Style
    > header {
      @extend %flex-between;

      background-color: $color;
      color: if($pp-color != null, $pp-color, $white);

      .btn {
        $text-color: nth(adjust-contrast($btn-home-bg, $color), 2);
        background-color: nth(adjust-contrast($btn-home-bg, $text-color), 1) !important;
        color: $text-color !important;
      }

      .pp-header-logo {
        @extend .#{$name}-header-logo;
      }
    }

    > .pp-steps {
      
      $text-color: nth(adjust-contrast($color, if($pp-color != null, $pp-color, $white)), 2);
      color: $text-color;

      .breadcrumb {
        background-color: nth(adjust-contrast(darken($color, 7%), $text-color), 1);


        .breadcrumb-item.active {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    > main {
      flex: 1 0 auto;

      process-nav {
        color: if($pp-color != null, $pp-color, $color);
      }
    }

    .btn-action, .btn-nav, .btn-pop {
      text-transform: uppercase;
    }

    .btn-nav {
      @extend .btn-lg;
      @include fully-colored-element(if($btn-nav-bg != null, $btn-nav-bg, $color));

      > .material-icons {
        font-size: 36px;
        line-height: 24px;
      }

      > span {
        vertical-align: middle;
      }
    }

    .btn-action {
      @include fully-colored-element($btn-action-bg);

      &.btn-payment-search {
        @include fully-colored-element(if($btn-payment-search-bg != null, $btn-payment-search-bg, $btn-action-bg));
      }

    }

    .btn-pop {
      @extend .btn-lg;
      @include fully-colored-element($btn-pop-bg);
    }

    .btn-prop-claim .material-icons {
      font-size: $btn-prop-claim-icon-size;
    }

    .btn-prop-share .material-icons {
      font-size: $btn-prop-share-icon-size;
    }

    .btn-file-upload {
      background-color: $btn-upload-color;
      border: 1px solid $btn-upload-color;
      color: $btn-upload-text-color;

      &:hover {
        background-color: darken($btn-upload-color, 10%);
        border: 1px solid darken($btn-upload-color, 10%);
      }
    }


  }
}
